.drawer {
  :global {
    .ant-drawer-body {
      display: flex;
      padding: 0;
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }

    .ant-drawer-close {
      margin: 0 !important;
    }
  }
}
