.root {
  font-family: Montserrat, sans-serif;
}

.left {
  font-weight: 700;
}

.right {
  font-weight: 400;
  font-style: italic;
}

.sup {
  top: -1em;
  font-size: 0.375em;
}
