.root {
  :global {
    .ant-card-head {
        padding: 16px 24px;
    }

    .ant-card-head-wrapper {
      align-items: start;
    }
  }
}
