.drawer {
  &:global(.ant-drawer-content) {
    overflow: visible;
  }

  :global {
    .ant-drawer-body {
      overflow: visible;
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }

    .ant-drawer-close {
      margin: 0 !important;
    }
  }
}
