.common {
  :global {
    .ant-tree-treenode {
      max-width: 100%;
    }

    .ant-tree-node-content-wrapper {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.flatTree {
  :global {
    .ant-tree-switcher {
      display: none;
    }
  }
}
