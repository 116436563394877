.collapse {
  border: none;
  border-radius: 0 !important;

  :global {
    .ant-collapse-item {
      border-radius: 0 !important;
    }

    .ant-collapse-header {
      border-radius: 0 !important;
    }
  }

  &:first-child {
    :global {
      .ant-collapse-header {
        border-top-width: 0;
      }
    }
  }
}
